<template>
  <div id="account-profile-content">
    <div id="account-profile-form">
      <h1 class="account-preferences-header">
        {{ lang_stanza.edit_profile }}
      </h1>
      <ValidationObserver>
        <div class="form-group acct-first-name">
          <label for="first-name">{{ lang_stanza.first_name }}</label>
          <ValidationProvider
            mode="lazy"
            rules="err_first_name_too_short:2|alpha|required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              name="first name"
              class="form-control"
              v-model="firstName"
            />
            <span id="error">{{ langLookup(errors[0]) }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group acct-last-name">
          <label for="last-name">{{ lang_stanza.last_name }}</label>
          <ValidationProvider
            mode="lazy"
            :rules="`err_last_name_too_short:2|err_last_name_non_alpha|err_last_name_required:${firstName}`"
            v-slot="{ errors }"
          >
            <input
              type="text"
              name="last-name"
              class="form-control"
              v-model="lastName"
            />
            <span id="error">{{ langLookup(errors[0]) }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group acct-email">
          <label for="email">{{ lang_stanza.email }}</label>
          <input
            type="email"
            name="email"
            class="form-control edit-email"
            disabled
            :value="userProfile.email"
          />
        </div>
      </ValidationObserver>
    </div>
    <div class="pass-field">
      <p class="pass-change-label">{{ lang_stanza.pass_field }}</p>
      <br />
      <span class="pass-change"
        ><a
          target="_blank"
          rel="noopener noreferrer"
          class="click-pass"
          href="https://uat.account.texasoncourse.org/passwords/reset"
          >Click here</a
        >
        to change your password with Texas OnCourse</span
      >
    </div>
    <school-district-selection></school-district-selection>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SchoolDistrictSelection from "../components/SchoolDistrictSelection";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { router, sessionStorage } from "../_helpers";

export default {
  name: "Profile",
  data() {
    return {
      firstName: "",
      lastName: "",
      lang_stanza_key: "profile",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let userSessionProfile = sessionStorage.decodeUserSession();
    if (!userSessionProfile || !userSessionProfile.sub) {
      await router.push("`/${this.lang_info.lang_code}/login`");
    }
    let oidc_id = userSessionProfile.sub;
    await this.getUserProfileByOidc(oidc_id);
    this.firstName = this.userProfile.first_name;
    this.lastName = this.userProfile.last_name;
  },
  methods: {
    ...mapActions("account", ["getUserProfileByOidc"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
  },
  computed: {
    ...mapState("account", ["userProfile"]),
  },
  components: {
    SchoolDistrictSelection,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 769px) {
  h1.account-preferences-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    margin-top: 67px;
    text-align: left;
    margin-bottom: 32px;
  }

  .account-preferences-subtitle {
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
  }

  .form-group > label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px !important;
  }

  .form-control {
    box-sizing: border-box;
    height: 64px;
    width: 456px;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .form-control:hover {
    border: 1px solid #047ac3;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .pass-field {
    text-align: left;
    margin-top: 50px;
    color: #030303;
    font-family: Lato;
    margin-bottom: -2.5px;
  }

  .pass-change {
    line-height: 24px;
    margin-top: 50px;
    font-size: 16px;
    margin-top: 50px;
    font-weight: 400;
  }

  .pass-change-label {
    margin-bottom: -18px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
  }

  .click-pass {
    font-size: 16px;
    color: #277ae6;
    font-weight: 700;
    text-decoration: underline;
  }

  .acct-first-name {
    font-weight: bold;
  }

  .acct-last-name {
    margin-top: 32px;
    font-weight: bold;
  }

  .acct-email {
    margin-top: 32px;
    font-weight: bold;
  }

  label.select-school {
    text-align: left;
  }

  .form-group {
    text-align: left;
  }

  .edit-email {
    margin-bottom: -18px !important;
    background-color: #e6e9ed !important;
    border: none !important;
  }

  #error {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fc4852;
    position: static;
    float: left;
  }
}
@media (min-width: 376px) and (max-width: 768px) {
  h1.account-preferences-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    margin-top: 32px;
    text-align: left;
    margin-bottom: 32px;
  }

  .account-preferences-subtitle {
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
  }

  .form-group > label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px !important;
  }

  .form-control {
    box-sizing: border-box;
    height: 64px;
    max-width: 456px;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .pass-field {
    text-align: left;
    margin-top: 50px;
    color: #030303;
    font-family: Lato;
    margin-bottom: -2.5px;
  }

  .pass-change {
    line-height: 24px;
    margin-top: 50px;
    font-size: 16px;
    margin-top: 50px;
    font-weight: 400;
  }

  .pass-change-label {
    margin-bottom: -18px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
  }

  .click-pass {
    font-size: 16px;
    color: #277ae6;
  }

  .acct-first-name {
    font-weight: bold;
  }

  .acct-last-name {
    margin-top: 32px;
    font-weight: bold;
  }

  .acct-email {
    margin-top: 32px;
    font-weight: bold;
  }

  label.select-school {
    text-align: left;
  }

  .form-group {
    text-align: left;
  }

  .edit-email {
    margin-bottom: -18px !important;
    background-color: #e6e9ed !important;
    border: none !important;
  }

  #error {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fc4852;
    position: static;
    float: left;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  h1.account-preferences-header {
    margin-bottom: 24px;
  }

  .acct-last-name {
    margin-top: 24px;
  }

  .acct-email {
    margin-top: 24px;
  }

  .pass-field {
    margin-top: 40px;
  }

  .edit-email {
    background-color: #e6e9ed !important;
  }

  
}

@media (max-width: 375px) {
  h1.account-preferences-header {
    margin-top: 32px;
    text-align: left;
    margin-bottom: 37px;
  }

  .account-preferences-subtitle {
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
  }

  .form-group > label {
    color: #033333;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    //font-weight: 400;
    margin-bottom: 3px !important;
  }

  .form-control {
    box-sizing: border-box;
    height: 64px;
    max-width: 456px;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .pass-field {
    text-align: left;
    margin-top: 50px;
    color: #030303;
    font-family: "Open Sans";
    margin-bottom: -2.5px;
  }

  .pass-change {
    line-height: 24px;
    margin-top: 50px;
    font-size: 16px;
    margin-top: 50px;
    font-weight: 400;
  }

  .pass-change-label {
    margin-bottom: -18px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
  }

  .click-pass {
    font-size: 16px;
    color: #277ae6;
  }

  .acct-first-name {
    font-weight: bold;
  }

  .acct-last-name {
    margin-top: 32px;
    font-weight: bold !important;
  }

  .acct-email {
    margin-top: 32px;
    font-weight: bold;
  }

  label.select-school {
    text-align: left;
  }

  .form-group {
    text-align: left;
  }

  .edit-email {
    margin-bottom: -18px !important;
    background-color: #e6e9ed !important;
    border: none !important;
  }

  #error {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fc4852;
    position: static;
    float: left;
  }
}
</style>
